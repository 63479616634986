import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-react-intl"
import { getImage } from "gatsby-plugin-image"
//import Slider from "react-slick"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import Footer from "../components/Footer"

import { Row, Col } from "antd"
import styled from "@emotion/styled"

const FullwidthContainerheader = styled.div`
    margin-bottom: 60px;
    font-family: "Kanit", sans-serif;
    .gb-container {
        padding-right: 15px;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        color: #7497B2;
        margin-bottom: 0.5em;
    }
    .title {
        font-weight: 400;
        font-size: 36px;
        color: #181818;
        margin-bottom: 0.1em;
    }
    .content {
        font-weight: 400;
        font-size: 16px;
        color: #646464;
        p {
        font-weight: 400;
        font-size: 16px;
        color: #646464;
        padding: 0px 15px;
        }
    }
    @media only screen and (min-width: 576px) {
        .gb-container {
            padding-right: 10%;
        }
    }

    @media only screen and (min-width: 576px) {
    .gb-container {
      padding-right: 10%;
    }
  }
  @media only screen and (min-width: 992px) {
    margin-bottom: 80px;
    .gb-container {
      padding-right: 15%;
    }
    p {
      font-size: 18px;
    }
    .title {
      font-size: 38px;
      margin-bottom: 0.3em;
    }
    .content {
      p {
        padding: 0px 23%;
        font-size: 18px;
      }
    }
  }
`

const ThreeColumnsContainerbuttom = styled.div`
  margin: 50px 0;
  .item {
    display: flex;
    flex-direction: column;
    align-items: left;
    font-family: "Kanit", sans-serif;
    padding: 50px 30px 50px 30px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.06);
    .icon {
      display: inline-block;
      width: 84px;
      height: 84px;
    }
    .title {
      font-weight: 500;
      font-size: 22px;
      color: #2b3940;
      margin-top: 40px;
      margin-bottom: 10px;
      text-align: left;
    }
    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 46px;
    }
    p {
      font-size: 18px;
      color: #646464;
      font-weight: 300;
      margin-bottom: 0rem;
      strong {
        font-weight: 600;
      }
      :nth-child(3) {
        margin-bottom: 1rem;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 50px 0;
  }
`

const DistributorPage = ({ data, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields } = data
  const {
    seoTitleDistributor,
    seoDescriptionDistributor,
    coverImageDistributor,
  } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImageDistributor)

  return (
    <Layout>
      <Seo lang={locale} title={seoTitleDistributor} description={seoDescriptionDistributor} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "distributorHead" })}
      />
      <FullwidthContainerheader
        style={{
          marginTop: "100px",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        <Row>
          <Col span={24}>
            <p>{intl.formatMessage({ id: "siteTitle" })}</p>
            <h1 className="title">{intl.formatMessage({ id: "distributorHead" })}</h1>
          </Col>
        </Row>
      </FullwidthContainerheader>
      <ThreeColumnsContainerbuttom
        style={{ marginTop: "50px", marginBottom: "100px" }}
      >
        <div className="gb-container">
          <Row gutter={[20, 20]}>
            <Col xs={24} md={8} lg={8}>
              <div className="item">
                <div>
                  <h3>{intl.formatMessage({ id: "P.OVERSEAS STEEL PLC." })}</h3>
                  <p><strong>{intl.formatMessage({ id: "Address" })}</strong></p>
                  <p>{intl.formatMessage({ id: "99/1 Soi Phaholyothin 40 Phaholyothin Rd., Senanikhom, Chatuchak, Bangkok 10900" })}</p>
                  <p><strong>{intl.formatMessage({ id: "Tel" })}</strong></p>
                  <p>02-562-0251 {intl.formatMessage({ id: "Sale" })}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8}>
              <div className="item">
                <div>
                  <h3>{intl.formatMessage({ id: "S.STEEL CENTER CO.,LTD." })}</h3>
                  <p><strong>{intl.formatMessage({ id: "Address" })}</strong></p>
                  <p>{intl.formatMessage({ id: "28/6 Moo 1 NAMAI LATLUMKAEW PATHUMTANI 12140" })}</p>
                  <p><strong>{intl.formatMessage({ id: "Tel" })}</strong></p>
                  <p>02-977-7702-4</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8}>
              <div className="item">
                <div>
                  <h3>{intl.formatMessage({ id: "SINKIT PHAIBOON LOHAKARN CO.,LTD." })}</h3>
                  <p><strong>{intl.formatMessage({ id: "Address" })}</strong></p>
                  <p>{intl.formatMessage({ id: "26/11 Nanglinchi Rd., Thung Maha Mek, Sathon, Bangkok 10120" })}</p>
                  <p><strong>{intl.formatMessage({ id: "Tel" })}</strong></p>
                  <p>02-678-3988</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8}>
              <div className="item">
                <div>
                  <h3>{intl.formatMessage({ id: "UNITED CONBUILD COMPANY LIMITED" })}</h3>
                  <p><strong>{intl.formatMessage({ id: "Address" })}</strong></p>
                  <p>{intl.formatMessage({ id: "250 Moo 2,  bangkayaeng, Mueang Pathumthani, Pathumthani 12000" })}</p>
                  <p><strong>{intl.formatMessage({ id: "Tel" })}</strong></p>
                  <p>02-5814266  {intl.formatMessage({ id: "Sale Dept. press" })} 112 , 113, 114</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8}>
              <div className="item">
                <div>
                  <h3>{intl.formatMessage({ id: "JSP INTER TECH CO.,LTD." })}</h3>
                  <p><strong>{intl.formatMessage({ id: "Address" })}</strong></p>
                  <p>{intl.formatMessage({ id: "52 Soi   Ekachai 76, Ekachai Rd., Bangbon, Bangkok, THAILAND 10150" })}</p>
                  <p><strong>{intl.formatMessage({ id: "Tel" })}</strong></p>
                  <p>02-894-2831-3</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8}>
              <div className="item">
                <div>
                  <h3>{intl.formatMessage({ id: "JIRACHAI METAL CO.,LTD." })}</h3>
                  <p><strong>{intl.formatMessage({ id: "Address" })}</strong></p>
                  <p>{intl.formatMessage({ id: "2 Narathiwat Ratchanakarin 26 Road, Chong Nonsi Yannawa Bangkok 10120" })}</p>
                  <p><strong>{intl.formatMessage({ id: "Tel" })}</strong></p>
                  <p>02-681-2846</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8}>
              <div className="item">
                <div>
                  <h3>{intl.formatMessage({ id: "K.V.P. CONSTRUCTION LIMITED" })}</h3>
                  <p><strong>{intl.formatMessage({ id: "Address" })}</strong></p>
                  <p>{intl.formatMessage({ id: "819/51 Moo 12 Bang Kaeo, Bang Phli, Samut Prakan 10540" })}</p>
                  <p><strong>{intl.formatMessage({ id: "Tel" })}</strong></p>
                  <p>02-090-0697-9</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </ThreeColumnsContainerbuttom>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query DistributorPage($locale: String) {
    pageFields: allContentfulCorporateGovernancePage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoTitleDistributor
          seoDescriptionDistributor
          coverImageDistributor {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    aboutFields: allContentfulAboutPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          gallery {
            gatsbyImageData(width: 750, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default injectIntl(DistributorPage)
